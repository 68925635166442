<template>
<div>

  <div style="width: 200px;margin: 0 auto;display: flex;align-content: center;justify-content: center;flex-direction: column;text-align: center">

    <div style="font-size: 40px;font-weight: 600;border-bottom: 2px cyan solid">
      项目展示
    </div>
    <span class="iconfont icon-hangkonghangtian-aerospace-xingxing-hangxing" style="font-size: 35px;color: #bfbfbf;">

    </span>

  </div>


  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="media p-3" >
          <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel" >
            <div class="carousel-inner d-none d-sm-none d-md-block" style="width: 500px;height: 350px">
              <div class="carousel-item active">
                <img src="./../../../stules/img/7.jpeg" class="d-block " style="width: 500px;height: 350px" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./../../../stules/img/8.png" class="d-block " style="width: 500px;height: 350px" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./../../../stules/img/9.jpeg" class="d-block " style="width: 500px;height: 350px" alt="...">
              </div>
            </div>
            <div class="carousel-inner d-block d-sm-block d-md-none d-lg-none d-xl-none" style="height: 180px">
              <div class="carousel-item active">
                <img src="./../../../stules/img/7.jpeg" class="d-block " style="width: 100%;height: 180px" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./../../../stules/img/8.png" class="d-block " style="width: 100%;height: 180px" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./../../../stules/img/9.jpeg" class="d-block " style="width: 100%;height: 180px" alt="...">
              </div>
            </div>
          </div>
          <div class="media-body p-5 d-none d-sm-none d-md-none d-lg-block">
            <h5 class="mt-0">项目展示</h5>

            专业团队

          </div>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: "index",
  mounted() {
    this.$('.carousel').carousel({
      interval: 3000
    })
  }
}
</script>

<style scoped>

</style>