<template>
<div style="margin: 50px 0;">

  <div style="width: 200px;margin: 0 auto;display: flex;align-content: center;justify-content: center;flex-direction: column;text-align: center">

    <div style="font-size: 40px;font-weight: 600;border-bottom: 2px cyan solid">
      热门项目
    </div>
    <span class="iconfont icon-hangkonghangtian-aerospace-xingxing-hangxing" style="font-size: 35px;color: #bfbfbf;">

    </span>

  </div>


  <div class="constant" style="overflow: hidden">
    <div class="row">

       <div class="col-sm-12 col-md-6 col-lg-3   sr-nav">
         <div class="media p-5 m-1 bg-light">
           <i class="iconfont icon-wechat" style="font-size: 35px"></i>
           <div class="media-body">
             <h5 class="mt-0">小程序定制开发</h5>
               专业团队，质量保证.
           </div>
         </div>
       </div>
      <div class="col-sm-12 col-md-6 col-lg-3  sr-nav">
        <div class="media p-5 m-1 bg-light">
          <i class="iconfont icon-APPBuilder-DMB" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">APP定制开发</h5>
             专业大牛，保驾护航.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3  sr-nav">
        <div class="media p-5 m-1 bg-light">
          <i class="iconfont icon-h5e" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">H5定制开发</h5>
              原生开发，只为够快.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6  col-lg-3  sr-nav">
        <div class="media p-5 m-1 bg-light">
          <i class="iconfont icon-PC" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">pc端定制开发</h5>
            原生开发，质量保证.
          </div>
        </div>
      </div>

    </div>
  </div>
<!--  <div style="display: flex;align-content: center;justify-content: space-around;">-->
<!--    -->
<!--    <div class="sr-nav">-->



<!--    </div>-->

<!--    <div class="sr-nav sr-nav1"></div>-->
<!--    <div class="sr-nav sr-nav2"></div>-->
<!--  </div>-->

</div>
</template>

<script>
import scrollReveal from 'scrollreveal';
export default {
name: "index",
  data () {
    return {
      scrollReveal: scrollReveal()
    }
  },
  created() {

  },
  mounted() {
    this.gosr()
  },
  methods:{

    gosr(){

      this.scrollReveal.reveal('.sr-nav', {
        // 动画的时长
        duration: 500,
        // 延迟时间
        delay: 0,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'top',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: '200px',
        // 其他可用的动画效果
        opacity: 0.001,
        easing: 'linear',
        scale: 0.9,
      });



    }

  }
}
</script>

<style scoped>

</style>