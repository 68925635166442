<template>
<div style="background: #171717;color: #B1B1B1;padding: 50px 0;">
<div class="container">
  <div class="row">

    <div class="col-lg-6 col-md-6 col-sm-12">

      <div class="row" >

        <ul class="offset-2 col-5">
          <li>十年经验</li>
          <li>不忘初心</li>
          <li>坚持创新</li>
        </ul>

        <ul class="col-5">
          <li>至诚服务</li>
          <li>专业大牛</li>
          <li>质量保证</li>
        </ul>

      </div>

    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="row" >
        <ul class="col-6">
          <a href="https://beian.miit.gov.cn/?spm=a2cmq.17630005.0.0.192579fe0eVPkr#/Integrated/recordQuery">
            <li>黔ICP备20001278号-1</li>
          </a>
        </ul>
        <ul class="col-6">
          <li>联系电话15285910538</li>
          <li>座机：82216458</li>
        </ul>
      </div>
    </div>


  </div>
</div>
</div>
</template>

<script>
export default {
name: "index"
}
</script>

<style scoped>
li{
  list-style: none;
  margin-top: 10px;
  cursor: pointer;
}

</style>