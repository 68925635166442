<template>
<div>

  <div class="stellar-bg d-none d-sm-none d-md-none d-lg-block d-xl-block" style="position: relative">


    <div class="box-nav">
     <div class="navTab">
       敬业
     </div><div class="navTab">
      精益
     </div><div class="navTab">
      专注
     </div><div class="navTab">
      创新
     </div>

    </div>



  </div>

  <div class="stellar-bg1 d-none d-sm-none d-md-block d-lg-none d-xl-none">

    <div class="box-nav1">
      <div class="navTab">
        敬业
      </div><div class="navTab">
      精益
    </div><div class="navTab">
      专注
    </div><div class="navTab">
      创新
    </div>

    </div>


  </div>

</div>
</template>

<script>
export default {
name: "index"
}
</script>

<style scoped>
.stellar-bg,.stellar-bg1{
  width: 100%;
  background: fixed url("./../../../stules/img/6.jpeg") ;
}
.stellar-bg{
  height: 500px;
}
.stellar-bg1{
  height: 350px;
}
.box-nav{
  font-size: 30px;
  color: white;
  width: 900px;
  position: absolute;
  top: 50%;left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.box-nav1{
  font-size: 30px;
  color: white;
  width: 900px;
  position: absolute;
  top: 138%;left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.navTab{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(0,0,0,0.2);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>