<template>
<div class="bg-card">
  <div style="width: 200px;margin: 0 auto;display: flex;align-content: center;justify-content: center;flex-direction: column;text-align: center">

    <div style="font-size: 40px;font-weight: 600;border-bottom: 2px white solid;color: white">
      我们的优势
    </div>
    <span class="iconfont icon-hangkonghangtian-aerospace-xingxing-hangxing" style="font-size: 35px;color: #ffffff;">

    </span>

  </div>


  <div class="constant" style="overflow: hidden">
    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-3   sr-nav">
        <div class="media p-5 m-1" style="background: rgba(0,0,0,0.2);color: white">
          <i class="iconfont icon-zhuanyezhuanyeke" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">坚持初心</h5>
            十年行业经验，始终如一.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3  sr-nav">
        <div class="media p-5 m-1" style="background: rgba(0,0,0,0.2);color: white">
          <i class="iconfont icon-chuangxinchanpin" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">产品创新</h5>
            坚持产品创新，十年从未停止.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3  sr-nav">
        <div class="media p-5 m-1" style="background: rgba(0,0,0,0.2);color: white">
          <i class="iconfont icon-chanpin" style="font-size: 35px"></i>
          <div class="media-body">
            <h5 class="mt-0">自主研发</h5>
            全程自主研发，从桌面客户端Web到移动APP.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6  col-lg-3  sr-nav">
        <div class="media p-5 m-1" style="background: rgba(0,0,0,0.2);color: white">
          <i class="iconfont icon-fuwu" style="font-size: 35px"></i>
          <div class="media-body" >
            <h5 class="mt-0">至诚服务</h5>
            专业的实施团队、 标准化的实施流程、个性化的实施方案
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
name: "index"
}
</script>

<style scoped>
.bg-card{
  background: url("./../../../stules/img/inbg2.jpg");
  margin-top: 45px;
}
</style>