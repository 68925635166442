<template>
<div style="z-index: 999">

 <div >
   <nav class="navbar navbar-expand-lg navbar-light">
   <div class="container">

     <a class="navbar-brand" href="#">安心科技</a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
     </button>

     <div class="collapse navbar-collapse" id="navbarSupportedContent">
       <ul class="navbar-nav mr-auto">
         <li class="nav-item active">
           <a class="nav-link" href="#">首页（座机：82216458） <span class="sr-only">(current)</span></a>
         </li>
         <li class="nav-item">
           <a class="nav-link" href="#">关于我们（15285910538）</a>
         </li>

       </ul>
     </div>

   </div>
   </nav>
 </div>

</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>