<template>
<div>

  <div id="carouselExampleCaptions" class="carousel slide  carousel-fade" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" style="width: 100%">
      <div class="carousel-item active" style="width: 100%;">

        <div class=" w-100 csss d-none d-sm-none d-md-none d-lg-none d-xl-block" style="width: 100%;height: 820px;"></div>
        <div class=" w-100 csss d-none d-sm-none d-md-none d-lg-block d-xl-none" style="width: 100%;height: 650px;"></div>
        <div class=" w-100 csss d-none  d-sm-none d-md-block d-lg-none d-xl-none" style="width: 100%;height: 500px;"></div>
        <div class=" w-100 csss d-none  d-sm-block d-md-none d-lg-none d-xl-none" style="width: 100%;height: 300px;"></div>

        <img src="./../../../stules/img/3.jpg" class="w-100 csss d-block  d-sm-none d-md-none d-lg-none d-xl-none" style="width: 100%;height: 200px" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>工匠精神</h5>
          <p>敬业、精益、专注、创新</p>
        </div>
      </div>
      <div class="carousel-item">
<!--        <img src="./../../../stules/img/4.jpg" class="d-block w-100" alt="...">-->
        <div class="w-100 csss1 d-none d-sm-none d-md-none d-lg-none d-xl-block" style="width: 100%;height: 820px;"></div>
        <div class="w-100 csss1 d-none d-sm-none d-md-none d-lg-block d-xl-none" style="width: 100%;height: 650px;"></div>
        <div class="w-100 csss1 d-none  d-sm-none d-md-block d-lg-none d-xl-none" style="width: 100%;height: 500px;"></div>
        <div class="w-100 csss1 d-none  d-sm-block d-md-none d-lg-none d-xl-none" style="width: 100%;height: 300px;"></div>
        <img src="./../../../stules/img/4.jpg" class="w-100 csss d-block  d-sm-none d-md-none d-lg-none d-xl-none" style="width: 100%;height: 200px" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>团结共进,众志成城。</h5>
          <p> 成就团队等于成就个人。</p>
        </div>
      </div>
      <div class="carousel-item">
<!--        <img src="./../../../stules/img/5.jpg" class="d-block w-100" alt="...">-->
        <div class=" w-100 csss2 d-none d-sm-none d-md-none d-lg-none d-xl-block" style="width: 100%;height: 820px;"></div>
        <div class=" w-100 csss2 d-none d-sm-none d-md-none d-lg-block d-xl-none" style="width: 100%;height: 650px;"></div>
        <div class=" w-100 csss2 d-none  d-sm-none d-md-block d-lg-none d-xl-none" style="width: 100%;height: 500px;"></div>
        <div class="w-100 csss2 d-none  d-sm-block d-md-none d-lg-none d-xl-none" style="width: 100%;height: 300px;"></div>
        <img src="./../../../stules/img/5.jpg" class="w-100 csss d-block  d-sm-none d-md-none d-lg-none d-xl-none" style="width: 100%;height: 200px" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>社会精英</h5>
          <p>专业大牛为您保驾护航</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>



</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.csss{
  background-image: url('./../../../stules/img/3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.csss1{
  background-image: url('./../../../stules/img/4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.csss2{
  background-image: url('./../../../stules/img/5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>