<template>
<div>
  <topIndex></topIndex>
  <navbar></navbar>
  <swiperp></swiperp>
  <navTab></navTab>
  <stellar></stellar>
  <swiperTab></swiperTab>
  <cardTab></cardTab>
  <footerIndex></footerIndex>
</div>
</template>

<script>
import topIndex from "./top/topIndex"
import navbar from "./navbar/index"
import swiperp from "./swiper/index"
import stellar from "./stellar/index"
import navTab from "./navTab/index"
import swiperTab from "./swiperTab/index"
import cardTab from "./card/index"
import footerIndex from "./footer/index"
export default {
name: "index",
  components:{
    topIndex,
    navbar,
    swiperp,
    stellar,
    navTab,
    swiperTab,
    cardTab,
    footerIndex
  }
}
</script>

<style scoped>

</style>