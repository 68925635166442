<template>
<div class="header_top " >

  <div class="container d-none d-sm-none d-md-none d-lg-block text-secondary text-center ">
    <div class="row">
      <div class="col-md-3 wx-we">

        <div style="position: relative;z-index: 999">
          <i class="iconfont icon-wechat"></i>
          <span>关注微信</span>
          <img src="./../../../stules/img/wx.jpg" class="wx-img" alt="">
        </div>

<!--        <p class="iconfont icon-wechat"></p>-->
<!--        <p>关注微信</p>-->

      </div>
      <div class="col-md-5 we-phone">

        <div>
          <i class="iconfont icon-dianhua" style="font-size: 16px;"></i>
          <span>联系电话15285910538(工作日 9:00 - 18:00)</span>
        </div>

      </div>
      <div class="col-md-4 we-left">关于我们(座机：82216458)</div>
    </div>
  </div>


</div>
</template>

<script>
export default {
name: "topIndex"
}
</script>

<style scoped>
.header_top{
  height: 40px;
  line-height: 39px;
}
.wx-we:hover .wx-img{
 display: block;
}
.wx-we{
  cursor: pointer;
}
.wx-img{
  width: 150px;height: 150px;position: absolute;left:60px;top:40px;display: none;
}
.we-phone{
  border-left: 1px #e0e0e0 solid;
}
.we-left{
  border-left: 1px #e0e0e0 solid;
}
</style>